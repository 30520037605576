import React, { useMemo } from 'react';

import { Card, Text, Image, ImageResizeOptions } from 'wix-ui-tpa';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { ProductGallery } from '../ProductGallery';
import { ChatCard } from '../ChatCard';
import { PayloadCmpProps } from '../ChatMessagePayload/types';
import { ImageBlock } from '../ImageBlock';
import { LinkButton } from '../ButtonLink';

import { classes, st } from './Product.st.css';

type ProductProps = PayloadCmpProps & {
  images?: string[];
  title?: string;
  subtitle?: string;
  description?: string;
  price?: string;
  link?: string;
  noImagesGallery?: boolean;
};

export const Product: React.FC<
  ProductProps & React.HTMLAttributes<HTMLElement>
> = ({
  isSmall,
  images,
  title,
  subtitle,
  description,
  price,
  link,
  noImagesGallery,
  ...rootElementAttrs
}) => {
  const [t] = useTranslation();
  const hasInfoBlock = useMemo(
    () => Boolean(title || subtitle || description || price),
    [description, price, subtitle, title],
  );

  return (
    <ChatCard
      {...rootElementAttrs}
      className={`${st(classes.root)} ${rootElementAttrs.className}`}
      cardClassName={st(classes.card)}
      isSmall={Boolean(isSmall)}
      aria-label={t('app.widget.ariallabel.item')}
    >
      <Card.Container className={st(classes.section, classes.sectionGallery)}>
        {noImagesGallery ? (
          Boolean(images) && (
            <ImageBlock aspectRatio={1}>
              <Image
                className={st(classes.image)}
                src={images![0]}
                resize={ImageResizeOptions.cover}
                aspectRatio="square"
              />
            </ImageBlock>
          )
        ) : (
          <ProductGallery images={images ?? []} isSmall={Boolean(isSmall)} />
        )}
      </Card.Container>

      {hasInfoBlock && (
        <Card.Container
          className={st(classes.section, classes.sectionInfoBlock)}
        >
          {title && <Text className={st(classes.title)}>{title}</Text>}
          {subtitle && (
            <Text className={st(classes.description)}>{subtitle}</Text>
          )}
          {description && (
            <Text className={st(classes.description)}>{description}</Text>
          )}
          {price && <Text className={st(classes.description)}>{price}</Text>}
        </Card.Container>
      )}

      {link && (
        <Card.Container className={st(classes.section, classes.sectionLink)}>
          <LinkButton
            fullWidth
            link={link}
            title={t('app.widget.product.viewItem')}
          />
        </Card.Container>
      )}
    </ChatCard>
  );
};
