import React, { useMemo } from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { Message } from '@/features';

import { ScrollingGallery } from '../ScrollingGallery';

import { GenericPayload, PayloadCmpProps, SupportedPayloadType } from './types';
import { getPayloadsMapped, NOT_SUPPORTED_PAYLOAD } from './getPayloadsMapped';
import { payloadsOrder, payloadTypeToComponentConfig } from './config';
import { st, classes } from './ChatMessagePayload.st.css';

export interface ChatMessagePayloadProps {
  message: Message;
}

export const ChatMessagePayload: React.FC<ChatMessagePayloadProps> = ({
  message,
}) => {
  const { t } = useTranslation();
  const payloadSections = useMemo(() => {
    if (!message.structPayloads?.length) {
      return [];
    }
    const payloadsMapped = getPayloadsMapped(message);
    return [...payloadsOrder, NOT_SUPPORTED_PAYLOAD].reduce(
      (acc, payloadType) => {
        const payloads = payloadsMapped[
          payloadType as SupportedPayloadType
        ] as GenericPayload[];
        if (payloads) {
          const { useGallery } =
            payloadTypeToComponentConfig[payloadType as SupportedPayloadType] ??
            payloadTypeToComponentConfig.default;
          const isMultiple = payloads?.length > 1;
          const payloadsViews = payloads.map((payload) => (
            <div
              className={st(
                useGallery ? classes.galleryPayload : classes.payload,
              )}
            >
              <ChatMessagePayloadItem
                key={payload.id}
                payload={payload as GenericPayload}
                props={{
                  isSmall: isMultiple,
                  hasSpecialContainer: useGallery && isMultiple,
                }}
              />
            </div>
          ));
          acc.push(
            <div className={st(classes.payload)}>
              {useGallery && isMultiple ? (
                <ScrollingGallery arialLabel={t('app.widget.ariallabel.items')}>
                  {payloadsViews}
                </ScrollingGallery>
              ) : (
                <>{payloadsViews}</>
              )}
            </div>,
          );
        }
        return acc;
      },
      [] as JSX.Element[],
    );
  }, [message, t]);

  return <>{payloadSections}</>;
};

export interface ChatMessagePayloadItemProps {
  payload: GenericPayload;
  props?: PayloadCmpProps;
}

export const ChatMessagePayloadItem: React.FC<ChatMessagePayloadItemProps> = ({
  payload,
  props,
}) =>
  (
    payloadTypeToComponentConfig[payload.type as SupportedPayloadType] ??
    payloadTypeToComponentConfig.default
  ).builder(payload as GenericPayload as any, props ?? {});
